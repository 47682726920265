import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { FaQuoteRight } from 'react-icons/fa';
import data from './data';
function App() {
  const [people, setPeople] = useState(data)
  const [index, setIndex] = useState(0)
  useEffect(() => {
    let slider = setInterval(() => { handleNextButton() }, 3000)
    return () => clearInterval(slider)
  }, [index])
  const handleNextButton = () => {
    if (index === people.length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }

  }
  const handlePrevButton = () => {
    if (index === 0) {
      setIndex(people.length - 1)
    } else {
      setIndex(index - 1)
    }

  }

  return <section className="section">
    <div className="title">
      <h2>
        <span>/</span> Rewievs
      </h2>
    </div>
    <div className="section-center">
      {people.map((person, i) => {

        const { id, image, name, title, quote } = person
        const defineClass = (id) => {
          if ((index === 0 && id === people.length - 1) || id === index - 1) {
            return "lastSlide "
          }
          if ((index === people.length - 1 && id === 0) || id === index + 1) {
            return "nextSlide "
          }
          if (index === id) return "activeSlide "

        }
        return <article key={id} className={defineClass(id - 1)}>
          <img src={image} alt={name} className="person-img" />
          <h5>{name}</h5>
          <p className="title">{title}</p>
          <p className="text">{quote}</p>
          <FaQuoteRight className="icon" />
        </article>
      })}
      <button className="prev" onClick={handleNextButton}><FiChevronLeft /></button>
      <button className="next" onClick={handlePrevButton}><FiChevronRight /></button>
    </div>
  </section>;
}

export default App;
